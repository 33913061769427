<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 m-4">
      <vx-card v-if="registerSuccess == 1">
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col sm:w-full md:w-full lg:w-full d-theme-dark-bg">
              <div class="px-8 pt-8 pb-8">
                <div class="vx-card__title mb-4 text-center">
                  <h1 class="mb-5" style="font-weight: bold">Xác thực thành công!</h1>
                </div>
                <div class="vx-card__title mb-4 text-center">
                  <h4 class="mb-2">Bạn đã đăng ký tài khoản thành công.</h4>
                  <h4 class="mb-2">Chúc bạn có những trải nghiệm tuyệt vời cùng VMO AI</h4>
                </div>
                <div class="pt-5">
                    <vs-button to="/pages/login" style="width: 100%" size="large">Đăng nhập</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
      <vx-card v-if="registerSuccess == 0">
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col sm:w-full md:w-full lg:w-full d-theme-dark-bg">
              <div class="px-8 pt-8 pb-8">
                <div class="vx-card__title mb-4 text-center">
                  <h1 class="mb-5" style="font-weight: bold">Xác thực KHÔNG thành công!</h1>
                </div>
                <div class="vx-card__title mb-4 text-center">
                  <h4 class="mb-2">Bạn đã xác thực tài khoản không thành công.</h4>
                  <h4 class="mb-2">Vui lòng đăng nhập để xác thực lại.</h4>
                </div>
                <div class="pt-5">
                  <vs-button to="/pages/login" style="width: 100%" size="large">Đăng nhập</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        registerSuccess: 0
      }
    },
    created() {
      let request = {
        "id": this.$route.query.id,
        "otp": this.$route.query.otp,
      }
      this.$oauth.post('/registration/active', request).then(() => {
        this.registerSuccess = 1;
      }).catch(() => {
      });
    }
  };
</script>
